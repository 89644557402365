import * as Sentry from "@sentry/browser";
import { supabase } from "../../lib/supabase";

import { UserProfile } from "../../context/types";
import { $Enums } from "@prisma/client";

const _enrichUserData = (data = []): UserProfile => {
  let user = data[0] || {};
  let previousProducts = [];

  const productItems = (user.user_product_items || []).filter(
    (userProdItm) =>
      userProdItm.partial_order.status !==
        $Enums.OrderStatus.ORDER_PLACED_PAYMENT_CANCELLED &&
      userProdItm.partial_order.status !==
        $Enums.OrderStatus.ORDER_PLACED_PAYMENT_PENDING
  );

  //TODO: remove
  user.activeOrder = (user.partial_order || [])
    .filter(
      (pOrder) =>
        [
          $Enums.OrderStatus.ORDER_PLACED_PAYMENT_CANCELLED,
          $Enums.OrderStatus.ORDER_PLACED_PAYMENT_PENDING,
          $Enums.OrderStatus.ORDER_FINALISED,
        ].indexOf(pOrder.status) < 0
    )
    .pop();

  // TODO: remove
  user.activeReturn = (user.partial_return || [])
    .filter(
      (pOrder) =>
        [
          $Enums.OrderStatus.ORDER_PLACED,
          $Enums.OrderStatus.ORDER_ACTIVE,
        ].indexOf(pOrder.status) > -1
    )
    .pop();

  user.previousProductVariants = productItems.map(
    (userProdItem) => userProdItem.product_variant_id
  );

  return user;
};

export const getUserById = async (id): Promise<UserProfile> => {
  const { data, error } = await supabase
    .from("users")
    .select(
      `
      *,
      user_product_favorites(
        product_id
      ),
      partial_order(*),
      partial_return(*),
      user_product_items(*, product_variant_id, partial_order(*), partial_return(*)),
      order(
        id,
        user_id,
        created_at,
        status,
        delivery_date,
        delivery_date_time,
        delivery_status,
        return_date_time,
        subscription_type,
        order_product_variants (
          product_variant_id
        )
      )`
    )
    .eq("id", id.toString());

  if (error) {
    //TODO: handle error
    Sentry.addBreadcrumb({
      category: "Supabase",
      message: "Error getting user by id",
      data: {
        id,
      },
      level: "error",
    });
    Sentry.captureException(error);
  }

  return _enrichUserData(data);
};

export const updateUserProfile = async (
  {
    username,
    firstName,
    lastName,
    babyName,
    email,
    contactNumber,
    address1,
    address2,
    city,
    postcode,
    country,
    termsAccepted,
  }: UserProfile,
  id: string | number
) => {
  try {
    const updates = {
      id,
      username,
      firstName,
      lastName,
      babyName,
      email,
      contactNumber,
      address1,
      address2,
      city,
      postcode,
      country,
      termsAccepted,
    };

    const { data, error } = await supabase.from("users").upsert(updates, {
      returning: "representation", // Set to 'minimal' to not return the new values
    });

    if (error) {
      Sentry.captureException(error);
      throw error;
    }

    return data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const updateUserProfileBasic = async (
  updatedUserData: any,
  id: string | number
) => {
  try {
    // Update any of the fields from the user
    const { data, error } = await supabase.from("users").upsert(
      { id, ...updatedUserData },
      {
        returning: "minimal", // Set to 'minimal' to not return the new values
      }
    );

    if (error) {
      console.log("ERR", error);
    }
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};
